@mixin xxxl{
	@media (max-width: 1749.5px) {
		@content;
	}
}
@mixin xxl{
	@media (max-width: 1499.5px){
		@content;
	}
}
@mixin xl{
	@media (max-width: 1299.5px){
		@content;
	}
}
@mixin lg{
	@media (max-width: 1199.5px){
		@content;
	}
}
@mixin md{
	@media (max-width: 991.5px){
		@content;
	}
}
@mixin sm{
	@media (max-width: 767.5px){
		@content;
	}
}
@mixin xs{
	@media (max-width: 575.5px){
		@content;
	}
}

@mixin xxxl-up{
	@media (min-width: 1750px){
		@content;
	}
}
@mixin xxl-up{
	@media (min-width: 1500px){
		@content;
	}
}
@mixin xl-up{
	@media (min-width: 1300px){
		@content;
	}
}
@mixin lg-up{
	@media (min-width: 1200px){
		@content;
	}
}
@mixin md-up{
	@media (min-width: 992px){
		@content;
	}
}
@mixin sm-up{
	@media (min-width: 768px){
		@content;
	}
}
@mixin xs-up{
	@media (min-width: 576px){
		@content;
	}
}