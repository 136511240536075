.button{
	display: inline-block;
	text-align: center;
	cursor: pointer;
	border: none;
	&_wrap{
		text-align: center;
	}
	&-common{
		background: #6FCF97;
		border-radius: 4px;
		font-weight: bold;
		font-size: 23px;
		line-height: 28px;
		color: #FFFFFF;
		padding: 11px 19px;
		transition: background .3s, color .3s;
		@include xs{
			// padding-left: 36.5px;
			// padding-right: 36.5px;
			width: 100%;
		}
		&:hover{
			background: #82cf6f;
			transition: background .3s;
		}
	}
	&-second{
		background: #FFFFFF;
		border-radius: 4px;
		font-weight: bold;
		font-size: 23px;
		line-height: 28px;
		color: #4438B4;
		padding: 14px 22px;
		transition: background .3s, color .3s;
		@include xs{
			width: 100%;
		}
		&:hover{
			background: #4438B4;
			color: #FFFFFF;
			transition: background .3s, color .3s;
		}
	}
	&-subscribe{
		font-weight: 500;
		font-size: 23px;
		line-height: 28px;
		color: #FFFFFF;
		background: #6FCF97;
		border-radius: 4px 30px 30px 4px;
		padding: 17px 30px;
		transition: background .3s, color .3s;
		@include lg{
			border-radius: 4px;
		}
		@include xs{
			width: 100%;
		}
		&:hover{
			background: #82cf6f;
			transition: background .3s;
		}
	}
	&-link{
		font-size: 19px;
		line-height: 23px;
		text-decoration-line: underline;
		color: #FFFFFF;
		transition: color .3s;
		&:hover{
			color: #6FCF97;
			transition: color .3s;
		}
	}
}