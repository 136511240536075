.container{
	max-width: 1387px;
	margin: auto;
	padding-left: 15px;
	padding-right: 15px;
}
@media (max-width: 1199.5px){
	.container{
		max-width: 980px;
	}
}
@media (max-width: 991.5px){
	.container{
		max-width: none;
		padding-left: 0;
		padding-right: 0;
	}
}
.gmail .container-main{
	max-width: none;
	padding-left: 0;
}
.outlook .container-main{
	max-width: none;
	padding-left: 0;
}
// @media (max-width: 575.5px){
// 	.container{
// 		padding-left: 10px;
// 		padding-right: 10px;
// 	}
// }