* {
	margin: 0;
	outline: none;
	padding: 0;
	box-sizing: border-box;
}
*:after,*:before{
	box-sizing: border-box;
}
a {
	text-decoration: none;
}
::placeholder {
	color: #666;
}
::selection {
	background-color: #3390FF;
	color: #fff; 
	text-shadow: none;
}
b, strong {
	font-weight: bold;
}
ol, ul {
	list-style: none;
}
input, textarea {
	border: #666 1px solid;
	outline: none;
	&:focus:required:invalid {
		border-color: red;
	}
	&:required:valid {
		border-color: green;
	}
}
body {
	line-height: 1.15;
	min-width: 320px;
	width: 100%;
	height: 100%;
}
[hidden] {
	display: none;
}
textarea {
	overflow: auto;
	vertical-align: top;
	resize: vertical;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	cursor: pointer;
}
button[disabled],
input[disabled] {
	cursor: default;
}
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
sub {
	bottom: -0.25em;
}
sup {
	top: -0.5em;
}