// font-weight helper:

// 100 Extra Light or Ultra Light;
// 200 Light or Thin; 
// 300 Book or Demi;
// 400 Regular or Normal;
// 500 Medium;
// 600 Semibold or Demibold;
// 700 Bold;
// 800 Black or Extra Bold or Heavy;
// 900 Extra Black or Fat or Ultra Black

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
	@font-face {
		font-family: $font-family;
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
		src: url('#{$file-path}.woff') format('woff')
	}
}

@include font-face("HelveticaNeue", "../fonts/HelveticaNeueCyr-Bold", 700);
@include font-face("HelveticaNeue", "../fonts/HelveticaNeueCyr-Roman", 400);
@include font-face("Hind", "../fonts/Hindregular", 400);
@include font-face("Roboto", "../fonts/Roboto-Regular", 400);
@include font-face("Roboto", "../fonts/Roboto-Medium", 500);
@include font-face("Gugi", "../fonts/Gugi-Regular", 400);
@include font-face("Sansation", "../fonts/SansationRegular", 400);