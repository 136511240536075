.content{
	margin-top: 90px;
	@include md{
		margin-top: 44px;
	}
	.container-main{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding-top: 29px;
		@include lg{
			padding-top: 15px;
		}
		@include md{
			padding-top: 0;
		}
	}
}
.gmail .content{
	@include md-up{
		margin-top: 64px;
		@include md{
			margin-top: 0;
		}
		.container-main{
			padding-top: 0;
		}
	}
}
.outlook .content{
	@include md-up{
		margin-top: 64px;
		@include md{
			margin-top: 0;
		}
		.container-main{
			align-items: stretch;
			padding-top: 0;
		}
	}
}
.main{
	width: 100%;
	border: 1px solid #E0E0E0;
	border-radius: 4px 4px 0px 0px;
	overflow: hidden;
	padding-left: 17px;
	padding-right: 17px;
	position: relative;
	padding-top: 44.04px;
	@include lg{
		padding-top: 30px;
	}
	@include md{
		padding: 0;
		border: none;
		border-radius: 0;
	}
	&:before{
		content: '';
		width: 100%;
		height: 44.04px;
		background: #F2F2F2;
		border-bottom: 1px solid #E0E0E0;
		border-radius: 4px 4px 0px 0px;
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		@include lg{
			height: 30px;
		}
		@include md{
			content: none;
		}
	}
	&_container{
		padding-left: 12px;
		padding-right: 12px;
		max-width: 1083px;
		margin: auto;
		@include md{
			padding: 0;
			max-width: none;
		}
	}
	&_wrapper{
		border: 1px solid #F2F2F2;
		@include md{
			border: none;
		}
	}
}
.gmail .main{
	border: none;
	border-left: 1px solid #E4E8EB;
	border-radius: 0px;
	@include md{
		border: none;
	}
	&:before{
		content: none;
	}
	
}
.title_false_outlook{
	display: none;
}
.fixed_change{
	position: fixed;
	left: 10px;
	bottom: 10px;
	display: flex;
	z-index: 3;
	@include md{
		display: none;
	}
	& > div{
		width: 25px;
		height: 25px;
		background: blue;
		border-radius: 5px;
		margin-left: 20px;
		cursor: pointer;
		&:nth-of-type(2){
			background: red;
		}
		&:nth-of-type(3){
			background: black;
		}
	}
}
.outlook{
	.title_false_outlook{
		display: block;
		font-family: $font2;
		font-size: 22px;
		line-height: 35px;
		letter-spacing: -0.05em;
		color: #333333;
		margin-left: 10px;
		@include md{
			display: none;
		}
	}
	.main{
		border: none;
		border-radius: 0px;
		padding-top: 17px;
		padding-left: 0;
		padding-right: 0;
		@include md{
			padding-top: 0;
		}
		&:before{
			content: none;
		}
		.main_false_outlook{
			border: 1px solid #D0D0D0;
			border-radius: 2px 2px 0px 0px;
			padding-left: 17px;
			padding-right: 17px;
			@include md{
				padding-left: 0;
				padding-right: 0;
				border: none;
				border-radius: 0;
			}
		}
	}
}
.title-main{
	font-size: 60px;
	line-height: 72px;
	letter-spacing: -0.02em;
	color: #FFFFFF;
	font-weight: normal;
	@include lg{
		font-size: 45px;
		line-height: 50px;
	}
}
.title-common{
	font-weight: bold;
	font-size: 30px;
	line-height: 37px;
	color: #000000;
	text-align: center;
	@include xs{
		text-align: left;
	}
}
.pretitle-common{
	font-weight: bold;
	font-size: 19px;
	line-height: 23px;
	text-transform: uppercase;
	color: #FFFFFF;
}
.title-white{
	font-size: 41px;
	line-height: 49px;
	letter-spacing: -0.015em;
	color: #FFFFFF;
	font-weight: normal;
	@include lg{
		font-size: 35px;
		line-height: 41px;
	}
	@include md{
		font-size: 41px;
		line-height: 49px;
	}
}
.description-common{
	font-size: 24px;
	line-height: 29px;
	color: #FFFFFF;
	@include lg{
		font-size: 20px;
		line-height: 25px;
	}
	@include md{
		font-size: 24px;
		line-height: 29px;
	}
}
.checkbox-common{
	display: flex;
	align-items: center;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&:hover{
		.checkbox-common-text{
			&:before{
				background: #b9c4f0;
				transition: background .3s;
			}
		}
	}
	input[type=checkbox]{
		display: none;
		&:checked{
			& + .checkbox-common-text{
				&:before{
					box-shadow: none;
					background: #5C7BF7;
					transition: background .3s, box-shadow .3s;
				}
				&:after{
					left: 29px;
					transition: left .3s;
				}
			}
		}
	}
	&-text{
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		color: #828282;
	}
	&-false{
		padding-left: 71px;
		position: relative;
		display: block;
		&:before{
			content: '';
			width: 50px;
			height: 24px;
			border-radius: 12px;
			background: #CED5D6;
			box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			transition: background .3s, box-shadow .3s;
		}
		&:after{
			content: '';
			width: 18px;
			height: 18px;
			border-radius: 50%;
			background: #fff;
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
			display: block;
			position: absolute;
			left: 3px;
			bottom: 3px;
			transition: left .3s;
		}
	}
}
.radio-common{
	display: flex;
	align-items: center;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&:hover{
		.radio-common-text{
			&:after{
				background: #b9c4f0;
				transition: background .3s;
			}
		}
	}
	input[type=radio]{
		display: none;
		&:checked{
			& + .radio-common-text{
				&:after{
					box-shadow: none;
					background: #5C7BF7;
					transition: background .3s, box-shadow .3s;
				}
			}
		}
	}
	&-text{
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		color: #828282;
	}
	&-false{
		padding-left: 42px;
		position: relative;
		display: block;
		&:after{
			content: '';
			width: 18px;
			height: 18px;
			border-radius: 50%;
			background: #CED5D6;
			box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
			display: block;
			position: absolute;
			left: 0;
			bottom: 2px;
			transition: background .3s, box-shadow .3s;
		}
	}
}