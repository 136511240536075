.header{
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 15;
	.logo{
		font-family: $font3;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		line-height: 27px;
		color: #fff;
	}
	.link_mail{
		text-align: right;
		display: block;
		padding-top: 7px;
		padding-bottom: 7px;
		background: #fff;
		@include md{
			display: none;
		}
		.link{
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 15px;
			color: #005BD1;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	.top_panel{
		background: #005FF9;
		padding-top: 14px;
		padding-bottom: 14px;
		@include md{
			padding-top: 10px;
			padding-bottom: 7px;
			position: relative;
			z-index: 1;
		}
		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include md{
				justify-content: center;
				text-align: center;
			}
		}
		.link{
			display: none;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	.burger-mobile{
		display: none;
		height: 14px;
		width: 14px;
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		@include md{
			display: block;
		}
		&:after{
			content: '';
			width: 100%;
			height: 3px;
			background: #FFEAEA;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 2px;
			transform-origin: left center;
			transition: transform .3s, width .3s;
		}
		&:before{
			content: '';
			width: 100%;
			height: 3px;
			background: #FFEAEA;
			position: absolute;
			bottom: 0;
			border-radius: 2px;
			left: 0;
			transform-origin: left center;
			transition: transform .3s, width .3s;
		}
		span{
			width: 100%;
			height: 3px;
			background: #FFEAEA;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			border-radius: 2px;
			transition: width .3s;
		}
	}
	&.active{
		.burger-mobile{
			&:after{
				transform: rotate(45deg);
				width: 15.5px;
				transition: transform .3s, width .3s;
				
			}
			&:before{
				transform: rotate(-45deg);
				width: 15.5px;
				transition: transform .3s, width .3s;
			}
			span{
				width: 0;
				transition: width .3s;
			}
		}
	}
	.search{
		width: 261px;
		height: 30px;			
		background: #FFFFFF;
		border-radius: 2px;
		font-family: $font4;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 15px;
		color: #808080;
		position: relative;
		@include md{
			display: none;
		}
		input{
			width: 100%;
			height: 100%;
			padding: 8px 10px;
			background: transparent;
			border: none;
		}
		button{
			background: none;;
			border: none;
			width: 15px;
			height: 15px;
			overflow: hidden;
			position: absolute;
			right: 12px;
			top: 7px;
			svg{
				width: 15px;
				height: 15px;
			}
		}
		.arrow{
			position: absolute;
			right: 45px;
			top: 50%;
			transform: translateY(-50%);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 6px 3.5px 0 3.5px;
			border-color: #808080 transparent transparent transparent;
		}
		&-gmail{
			display: none;
			margin-left: 241px;
			background: #F7F8F9;
			border-radius: 3px;
			overflow: hidden;
			position: relative;
			width: 450px;
			height: 36px;
			input{
				background: transparent;
				border: none;
				font-family: $font1;
				font-size: 16px;
				line-height: 19px;
				padding: 8px 40px;
				&::placeholder{
					opacity: 0.8;
				}
			}
			button{
				background: none;;
				border: none;
				width: 30px;
				height: 30px;
				overflow: hidden;
				position: absolute;
				left: 6px;
				top: 3px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: background .2s;
				&:hover{
					background: #dcdcdc;
					transition: background .2s;
				}
				svg{
					width: 18px;
					height: 20px;
				}
			}
			.arrow{
				position: absolute;
				right: 13px;
				top: 50%;
				transform: translateY(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 6px 3.5px 0 3.5px;
				border-color: #909191 transparent transparent transparent;
			}
		}
		&-outlook{
			display: none;
			margin-left: 214px;
			background: #C4C4C4;
			border-radius: 3px;
			overflow: hidden;
			position: relative;
			width: 450px;
			height: 36px;
			input{
				background: transparent;
				border: none;
				font-family: $font1;
				font-size: 16px;
				line-height: 19px;
				padding: 8px 40px;
				color: #0062AF;
				&::placeholder{
					color: #0062AF;
					opacity: 0.8;
				}
			}
			button{
				background: none;;
				border: none;
				width: 30px;
				height: 30px;
				overflow: hidden;
				position: absolute;
				left: 6px;
				top: 3px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: background .2s;
				&:hover{
					background: #dcdcdc;
					transition: background .2s;
				}
				svg{
					width: 18px;
					height: 20px;
				}
			}
		}
	}
}
.gmail{
	@include md-up{
		.header{
			.link_mail{
				display: none;
			}
			.top_panel{
				background: #FFFFFF;
				box-shadow: inset 0px -1px 0px #E4E8EB;
				.link{
					display: block;
					font-weight: bold;
					font-size: 12px;
					line-height: 15px;
					color: #828282;
				}
				.container{
					max-width: none;
					align-items: center;
				}
			}
			.logo{
				position: absolute;
				top: 18px;
				left: 70px;
				color: #C4C4C4;
			}
			.search{
				display: none;
				&-gmail{
					display: block;
				}
			}
		}
	}
}
.outlook{
	@include md-up{
		.header{
			.link_mail{
				display: none;
			}
			.top_panel{
				background: #131212;
				.link{
					display: block;
					font-weight: bold;
					font-size: 13px;
					line-height: 15px;
					color: #fff;
				}
				.container{
					max-width: none;
					align-items: center;
					padding-left: 0;
				}
			}
			.logo{
				position: absolute;
				top: 19px;
				left: 44px;
				color: #FFFFFF;
			}
			.search{
				display: none;
				&-outlook{
					display: block;
				}
			}
		}
	}
}
.mob_menu{
	display: none;
	width: 100%;
	background: #FFFFFF;
	border: 1px solid #F2F2F2;
	box-sizing: border-box;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
	border-radius: 2px;
	text-align: center;
	transform: (translateY(-100%));
	pointer-events: none;
	height: 0;
	overflow: hidden;
	transition: transform .3s;
	a{
		font-size: 19px;
		line-height: 23px;
		text-transform: uppercase;
		color: #005BD1;
		display: block;
		padding-top: 7px;
		padding-bottom: 7px;
		&.active{
			background: #F2F2F2;
			font-weight: bold;
		}
	}
	@include md{
		display: block;
	}
}
.active{
	height: 100%;
	.mob_menu{
		transform: (translateY(0));
		pointer-events: all;
		height: 100%;
		transition: transform .3s;
	}
}