* {
  margin: 0;
  outline: none;
  padding: 0;
  box-sizing: border-box;
}

*:after, *:before {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

::placeholder {
  color: #666;
}

::selection {
  background-color: #3390FF;
  color: #fff;
  text-shadow: none;
}

b, strong {
  font-weight: bold;
}

ol, ul {
  list-style: none;
}

input, textarea {
  border: #666 1px solid;
  outline: none;
}

input:focus:required:invalid, textarea:focus:required:invalid {
  border-color: red;
}

input:required:valid, textarea:required:valid {
  border-color: green;
}

body {
  line-height: 1.15;
  min-width: 320px;
  width: 100%;
  height: 100%;
}

[hidden] {
  display: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

@font-face {
  font-family: "HelveticaNeue";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Bold.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeue";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/HelveticaNeueCyr-Roman.woff") format("woff");
}

@font-face {
  font-family: "Hind";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Hindregular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gugi";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Gugi-Regular.woff") format("woff");
}

@font-face {
  font-family: "Sansation";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/SansationRegular.woff") format("woff");
}

html {
  font-size: 10px;
}

body {
  font-size: 16px;
  position: relative;
  font-family: "HelveticaNeue", sans-serif;
  color: #070707;
}

#site {
  overflow: hidden;
  position: relative;
  background: #fff;
}

picture {
  font-size: 0;
  display: inline-block;
}

input, textarea {
  font-family: "HelveticaNeue", sans-serif;
}

main {
  position: relative;
  z-index: 1;
  background: #fff;
}

.content {
  margin-top: 90px;
}

@media (max-width: 991.5px) {
  .content {
    margin-top: 44px;
  }
}

.content .container-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 29px;
}

@media (max-width: 1199.5px) {
  .content .container-main {
    padding-top: 15px;
  }
}

@media (max-width: 991.5px) {
  .content .container-main {
    padding-top: 0;
  }
}

@media (min-width: 992px) {
  .gmail .content {
    margin-top: 64px;
  }
}

@media (min-width: 992px) and (max-width: 991.5px) {
  .gmail .content {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .gmail .content .container-main {
    padding-top: 0;
  }
}

@media (min-width: 992px) {
  .outlook .content {
    margin-top: 64px;
  }
}

@media (min-width: 992px) and (max-width: 991.5px) {
  .outlook .content {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .outlook .content .container-main {
    align-items: stretch;
    padding-top: 0;
  }
}

.main {
  width: 100%;
  border: 1px solid #E0E0E0;
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  padding-top: 44.04px;
}

@media (max-width: 1199.5px) {
  .main {
    padding-top: 30px;
  }
}

@media (max-width: 991.5px) {
  .main {
    padding: 0;
    border: none;
    border-radius: 0;
  }
}

.main:before {
  content: '';
  width: 100%;
  height: 44.04px;
  background: #F2F2F2;
  border-bottom: 1px solid #E0E0E0;
  border-radius: 4px 4px 0px 0px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 1199.5px) {
  .main:before {
    height: 30px;
  }
}

@media (max-width: 991.5px) {
  .main:before {
    content: none;
  }
}

.main_container {
  padding-left: 12px;
  padding-right: 12px;
  max-width: 1083px;
  margin: auto;
}

@media (max-width: 991.5px) {
  .main_container {
    padding: 0;
    max-width: none;
  }
}

.main_wrapper {
  border: 1px solid #F2F2F2;
}

@media (max-width: 991.5px) {
  .main_wrapper {
    border: none;
  }
}

.gmail .main {
  border: none;
  border-left: 1px solid #E4E8EB;
  border-radius: 0px;
}

@media (max-width: 991.5px) {
  .gmail .main {
    border: none;
  }
}

.gmail .main:before {
  content: none;
}

.title_false_outlook {
  display: none;
}

.fixed_change {
  position: fixed;
  left: 10px;
  bottom: 10px;
  display: flex;
  z-index: 3;
}

@media (max-width: 991.5px) {
  .fixed_change {
    display: none;
  }
}

.fixed_change > div {
  width: 25px;
  height: 25px;
  background: blue;
  border-radius: 5px;
  margin-left: 20px;
  cursor: pointer;
}

.fixed_change > div:nth-of-type(2) {
  background: red;
}

.fixed_change > div:nth-of-type(3) {
  background: black;
}

.outlook .title_false_outlook {
  display: block;
  font-family: "Hind", sans-serif;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: -0.05em;
  color: #333333;
  margin-left: 10px;
}

@media (max-width: 991.5px) {
  .outlook .title_false_outlook {
    display: none;
  }
}

.outlook .main {
  border: none;
  border-radius: 0px;
  padding-top: 17px;
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 991.5px) {
  .outlook .main {
    padding-top: 0;
  }
}

.outlook .main:before {
  content: none;
}

.outlook .main .main_false_outlook {
  border: 1px solid #D0D0D0;
  border-radius: 2px 2px 0px 0px;
  padding-left: 17px;
  padding-right: 17px;
}

@media (max-width: 991.5px) {
  .outlook .main .main_false_outlook {
    padding-left: 0;
    padding-right: 0;
    border: none;
    border-radius: 0;
  }
}

.title-main {
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  font-weight: normal;
}

@media (max-width: 1199.5px) {
  .title-main {
    font-size: 45px;
    line-height: 50px;
  }
}

.title-common {
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #000000;
  text-align: center;
}

@media (max-width: 575.5px) {
  .title-common {
    text-align: left;
  }
}

.pretitle-common {
  font-weight: bold;
  font-size: 19px;
  line-height: 23px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.title-white {
  font-size: 41px;
  line-height: 49px;
  letter-spacing: -0.015em;
  color: #FFFFFF;
  font-weight: normal;
}

@media (max-width: 1199.5px) {
  .title-white {
    font-size: 35px;
    line-height: 41px;
  }
}

@media (max-width: 991.5px) {
  .title-white {
    font-size: 41px;
    line-height: 49px;
  }
}

.description-common {
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
}

@media (max-width: 1199.5px) {
  .description-common {
    font-size: 20px;
    line-height: 25px;
  }
}

@media (max-width: 991.5px) {
  .description-common {
    font-size: 24px;
    line-height: 29px;
  }
}

.checkbox-common {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.checkbox-common:hover .checkbox-common-text:before {
  background: #b9c4f0;
  transition: background .3s;
}

.checkbox-common input[type=checkbox] {
  display: none;
}

.checkbox-common input[type=checkbox]:checked + .checkbox-common-text:before {
  box-shadow: none;
  background: #5C7BF7;
  transition: background .3s, box-shadow .3s;
}

.checkbox-common input[type=checkbox]:checked + .checkbox-common-text:after {
  left: 29px;
  transition: left .3s;
}

.checkbox-common-text {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #828282;
}

.checkbox-common-false {
  padding-left: 71px;
  position: relative;
  display: block;
}

.checkbox-common-false:before {
  content: '';
  width: 50px;
  height: 24px;
  border-radius: 12px;
  background: #CED5D6;
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: background .3s, box-shadow .3s;
}

.checkbox-common-false:after {
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  display: block;
  position: absolute;
  left: 3px;
  bottom: 3px;
  transition: left .3s;
}

.radio-common {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.radio-common:hover .radio-common-text:after {
  background: #b9c4f0;
  transition: background .3s;
}

.radio-common input[type=radio] {
  display: none;
}

.radio-common input[type=radio]:checked + .radio-common-text:after {
  box-shadow: none;
  background: #5C7BF7;
  transition: background .3s, box-shadow .3s;
}

.radio-common-text {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #828282;
}

.radio-common-false {
  padding-left: 42px;
  position: relative;
  display: block;
}

.radio-common-false:after {
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #CED5D6;
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  display: block;
  position: absolute;
  left: 0;
  bottom: 2px;
  transition: background .3s, box-shadow .3s;
}

.container {
  max-width: 1387px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 1199.5px) {
  .container {
    max-width: 980px;
  }
}

@media (max-width: 991.5px) {
  .container {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.gmail .container-main {
  max-width: none;
  padding-left: 0;
}

.outlook .container-main {
  max-width: none;
  padding-left: 0;
}

.button {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: none;
}

.button_wrap {
  text-align: center;
}

.button-common {
  background: #6FCF97;
  border-radius: 4px;
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #FFFFFF;
  padding: 11px 19px;
  transition: background .3s, color .3s;
}

@media (max-width: 575.5px) {
  .button-common {
    width: 100%;
  }
}

.button-common:hover {
  background: #82cf6f;
  transition: background .3s;
}

.button-second {
  background: #FFFFFF;
  border-radius: 4px;
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #4438B4;
  padding: 14px 22px;
  transition: background .3s, color .3s;
}

@media (max-width: 575.5px) {
  .button-second {
    width: 100%;
  }
}

.button-second:hover {
  background: #4438B4;
  color: #FFFFFF;
  transition: background .3s, color .3s;
}

.button-subscribe {
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
  color: #FFFFFF;
  background: #6FCF97;
  border-radius: 4px 30px 30px 4px;
  padding: 17px 30px;
  transition: background .3s, color .3s;
}

@media (max-width: 1199.5px) {
  .button-subscribe {
    border-radius: 4px;
  }
}

@media (max-width: 575.5px) {
  .button-subscribe {
    width: 100%;
  }
}

.button-subscribe:hover {
  background: #82cf6f;
  transition: background .3s;
}

.button-link {
  font-size: 19px;
  line-height: 23px;
  text-decoration-line: underline;
  color: #FFFFFF;
  transition: color .3s;
}

.button-link:hover {
  color: #6FCF97;
  transition: color .3s;
}

.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 15;
}

.header .logo {
  font-family: "Gugi", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  color: #fff;
}

.header .link_mail {
  text-align: right;
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
  background: #fff;
}

@media (max-width: 991.5px) {
  .header .link_mail {
    display: none;
  }
}

.header .link_mail .link {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #005BD1;
}

.header .link_mail .link:hover {
  text-decoration: underline;
}

.header .top_panel {
  background: #005FF9;
  padding-top: 14px;
  padding-bottom: 14px;
}

@media (max-width: 991.5px) {
  .header .top_panel {
    padding-top: 10px;
    padding-bottom: 7px;
    position: relative;
    z-index: 1;
  }
}

.header .top_panel .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991.5px) {
  .header .top_panel .container {
    justify-content: center;
    text-align: center;
  }
}

.header .top_panel .link {
  display: none;
}

.header .top_panel .link:hover {
  text-decoration: underline;
}

.header .burger-mobile {
  display: none;
  height: 14px;
  width: 14px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 991.5px) {
  .header .burger-mobile {
    display: block;
  }
}

.header .burger-mobile:after {
  content: '';
  width: 100%;
  height: 3px;
  background: #FFEAEA;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  transform-origin: left center;
  transition: transform .3s, width .3s;
}

.header .burger-mobile:before {
  content: '';
  width: 100%;
  height: 3px;
  background: #FFEAEA;
  position: absolute;
  bottom: 0;
  border-radius: 2px;
  left: 0;
  transform-origin: left center;
  transition: transform .3s, width .3s;
}

.header .burger-mobile span {
  width: 100%;
  height: 3px;
  background: #FFEAEA;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  border-radius: 2px;
  transition: width .3s;
}

.header.active .burger-mobile:after {
  transform: rotate(45deg);
  width: 15.5px;
  transition: transform .3s, width .3s;
}

.header.active .burger-mobile:before {
  transform: rotate(-45deg);
  width: 15.5px;
  transition: transform .3s, width .3s;
}

.header.active .burger-mobile span {
  width: 0;
  transition: width .3s;
}

.header .search {
  width: 261px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 2px;
  font-family: "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #808080;
  position: relative;
}

@media (max-width: 991.5px) {
  .header .search {
    display: none;
  }
}

.header .search input {
  width: 100%;
  height: 100%;
  padding: 8px 10px;
  background: transparent;
  border: none;
}

.header .search button {
  background: none;
  border: none;
  width: 15px;
  height: 15px;
  overflow: hidden;
  position: absolute;
  right: 12px;
  top: 7px;
}

.header .search button svg {
  width: 15px;
  height: 15px;
}

.header .search .arrow {
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 3.5px 0 3.5px;
  border-color: #808080 transparent transparent transparent;
}

.header .search-gmail {
  display: none;
  margin-left: 241px;
  background: #F7F8F9;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  width: 450px;
  height: 36px;
}

.header .search-gmail input {
  background: transparent;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 19px;
  padding: 8px 40px;
}

.header .search-gmail input::placeholder {
  opacity: 0.8;
}

.header .search-gmail button {
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: absolute;
  left: 6px;
  top: 3px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background .2s;
}

.header .search-gmail button:hover {
  background: #dcdcdc;
  transition: background .2s;
}

.header .search-gmail button svg {
  width: 18px;
  height: 20px;
}

.header .search-gmail .arrow {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 3.5px 0 3.5px;
  border-color: #909191 transparent transparent transparent;
}

.header .search-outlook {
  display: none;
  margin-left: 214px;
  background: #C4C4C4;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  width: 450px;
  height: 36px;
}

.header .search-outlook input {
  background: transparent;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 19px;
  padding: 8px 40px;
  color: #0062AF;
}

.header .search-outlook input::placeholder {
  color: #0062AF;
  opacity: 0.8;
}

.header .search-outlook button {
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: absolute;
  left: 6px;
  top: 3px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background .2s;
}

.header .search-outlook button:hover {
  background: #dcdcdc;
  transition: background .2s;
}

.header .search-outlook button svg {
  width: 18px;
  height: 20px;
}

@media (min-width: 992px) {
  .gmail .header .link_mail {
    display: none;
  }
  .gmail .header .top_panel {
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px #E4E8EB;
  }
  .gmail .header .top_panel .link {
    display: block;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #828282;
  }
  .gmail .header .top_panel .container {
    max-width: none;
    align-items: center;
  }
  .gmail .header .logo {
    position: absolute;
    top: 18px;
    left: 70px;
    color: #C4C4C4;
  }
  .gmail .header .search {
    display: none;
  }
  .gmail .header .search-gmail {
    display: block;
  }
}

@media (min-width: 992px) {
  .outlook .header .link_mail {
    display: none;
  }
  .outlook .header .top_panel {
    background: #131212;
  }
  .outlook .header .top_panel .link {
    display: block;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #fff;
  }
  .outlook .header .top_panel .container {
    max-width: none;
    align-items: center;
    padding-left: 0;
  }
  .outlook .header .logo {
    position: absolute;
    top: 19px;
    left: 44px;
    color: #FFFFFF;
  }
  .outlook .header .search {
    display: none;
  }
  .outlook .header .search-outlook {
    display: block;
  }
}

.mob_menu {
  display: none;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  text-align: center;
  transform: translateY(-100%);
  pointer-events: none;
  height: 0;
  overflow: hidden;
  transition: transform .3s;
}

.mob_menu a {
  font-size: 19px;
  line-height: 23px;
  text-transform: uppercase;
  color: #005BD1;
  display: block;
  padding-top: 7px;
  padding-bottom: 7px;
}

.mob_menu a.active {
  background: #F2F2F2;
  font-weight: bold;
}

@media (max-width: 991.5px) {
  .mob_menu {
    display: block;
  }
}

.active {
  height: 100%;
}

.active .mob_menu {
  transform: translateY(0);
  pointer-events: all;
  height: 100%;
  transition: transform .3s;
}
